/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import { Select, MenuItem } from '@material-ui/core';
import moment from 'moment-timezone';
import FlatButton from 'material-ui/FlatButton';
import Snackbar from 'material-ui/Snackbar';
import _ from 'lodash';
import TopBar from './partials/topBar';
import GlobalPanel from '../global/Panel';
import DataLoadingSpinner from '../global/DataLoadingSpinner';
import ConfirmationDialog from '../global/ConfirmationDialog';
import {
  fetchSharedWithAll,
  removeFileSharedToAll,
} from '../../actions/resources';

const propTypes = {
  fetchSharedWithAll: PropTypes.func.isRequired,
  removeFileSharedToAll: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

class FilesToAllClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'date-newest',
      snack: false,
      snackMessage: '',
      documents: [],
      loading: false,
      openDeleteConfirm: false,
      deletingFileId: null,
    };
  }

  componentDidMount = () => {
    this.fetchList();
  };

  fetchList = () => {
    this.setState({ loading: true });
    this.props.fetchSharedWithAll((resources, error) => {
      this.setState({ loading: false });
      if (!error) {
        this.setState({
          documents: _.map(resources, resource => resource),
        });
      }
    });
  };

  handleRequestClose = () => {
    this.setState({ snack: false });
  };

  openSnack = message => {
    this.setState({
      snack: true,
      snackMessage: message,
    });
  };

  showDeleteConfirm = deletingFileId =>
    this.setState({ deletingFileId, openDeleteConfirm: true });

  hideDeleteConfirm = () => this.setState({ openDeleteConfirm: false });

  handleChange = event => {
    this.setState({ sortBy: event.target.value });
  };

  handleRemoveFileSharedToAll = id => {
    this.props.removeFileSharedToAll(id, () => {
      this.openSnack(
        <FormattedMessage defaultMessage="Successfully deleted file" />,
      );
      this.fetchList();
    });
  };

  renderRows() {
    const { documents, sortBy } = this.state;

    if (_.isEmpty(documents)) {
      return (
        <TableRow>
          <TableRowColumn>
            <FormattedMessage defaultMessage="No Files" />
          </TableRowColumn>
        </TableRow>
      );
    }

    const filteredDocuments = [...documents];

    // sort
    filteredDocuments.sort((leftDoc, rightDoc) => {
      switch (sortBy) {
        case 'date-newest':
          return moment(rightDoc.created_at).diff(moment(leftDoc.created_at));
        case 'date-oldest':
          return moment(leftDoc.created_at).diff(moment(rightDoc.created_at));
        default:
      }
      return 0;
    });

    const { formatDate } = this.props.intl;

    return _.map(filteredDocuments, (document, index) => (
      <TableRow key={`ksc-${index + 1}`}>
        <TableRowColumn>
          {formatDate(_.get(document, 'created_at', ''), {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: localStorage.getItem('current-timezone'),
          })}
        </TableRowColumn>
        <TableRowColumn>
          {_.get(document, 'file_name', '').replace(/__([a-zA-Z0-9])+\./, '.')}
        </TableRowColumn>
        <TableRowColumn>
          <div style={{ display: 'flex' }}>
            <FlatButton
              href={`${_.get(document, 'resource.file.url', '')}`}
              className="flat-button-baby-blue process-action-button pab-small"
              label={<FormattedMessage defaultMessage="View" />}
              download
              target="_blank"
            />
            &nbsp;
            <FlatButton
              onClick={() =>
                this.showDeleteConfirm(_.get(document, 'id', null))
              }
              className="flat-button-red process-action-button pab-small"
              label={<FormattedMessage defaultMessage="Remove" />}
            />
          </div>
        </TableRowColumn>
      </TableRow>
    ));
  }

  renderTable = () => (
    <div className="module-table">
      <Table
        style={{ marginLeft: 0 }}
        className="appointment-table"
        selectable={false}
      >
        <TableHeader
          className="remove-border"
          displaySelectAll={false}
          adjustForCheckbox={false}
        >
          <TableRow className="remove-border" selectable={false}>
            <TableHeaderColumn>
              <FormattedMessage defaultMessage="Date" />
            </TableHeaderColumn>
            <TableHeaderColumn>
              <FormattedMessage defaultMessage="File" />
            </TableHeaderColumn>
            <TableHeaderColumn>
              <FormattedMessage defaultMessage="Action" />
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>{this.renderRows()}</TableBody>
      </Table>
    </div>
  );

  render() {
    const {
      snack,
      snackMessage,
      loading,
      openDeleteConfirm,
      deletingFileId,
    } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <TopBar activeTab="files_to_all_clients" />
        <GlobalPanel>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              marginBottom: 30,
            }}
          >
            <Select value={this.state.sortBy} onChange={this.handleChange}>
              <MenuItem value="date-newest">
                {formatMessage({
                  defaultMessage: 'Date - Newest First',
                })}
              </MenuItem>
              <MenuItem value="date-oldest">
                {formatMessage({
                  defaultMessage: 'Date - Oldest First',
                })}
              </MenuItem>
            </Select>
          </div>
          {loading ? <DataLoadingSpinner /> : this.renderTable()}
        </GlobalPanel>
        {openDeleteConfirm && (
          <ConfirmationDialog
            open={openDeleteConfirm}
            message={formatMessage({
              defaultMessage: 'Are you sure you want to delete this file?',
            })}
            handleConfirm={() =>
              this.handleRemoveFileSharedToAll(deletingFileId)
            }
            handleClose={this.hideDeleteConfirm}
          />
        )}
        <Snackbar
          open={snack}
          message={snackMessage}
          autoHideDuration={4000}
          onRequestClose={this.handleRequestClose}
        />
      </div>
    );
  }
}

FilesToAllClients.propTypes = propTypes;

const mapStateToProps = state => ({
  resources: state.resources,
});

export default injectIntl(
  connect(mapStateToProps, {
    fetchSharedWithAll,
    removeFileSharedToAll,
  })(FilesToAllClients),
);
