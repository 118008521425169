import React, { Component } from 'react';
import _ from 'lodash';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import PropTypes from 'prop-types';
import ClientSelector from '../schedule/form/ClientSelector';
import DropzoneInput from '../global/DropzoneInput';
import { createResource } from '../../actions/resources';

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  createResource: PropTypes.func.isRequired,
  openSnack: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  file: PropTypes.array,
  intl: PropTypes.object.isRequired,
};

class SendFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = values => {
    const body = new FormData();
    body.append('file', values.file[0]);
    body.append('client', values.client?.value);
    this.props.createResource(body, () => {
      this.props.closeModal();
      this.props.openSnack(
        <FormattedMessage defaultMessage="Successfully uploaded file." />,
      );
      this.props.reset();
    });
  };

  render() {
    const { handleSubmit, file, intl } = this.props;
    const { formatMessage } = intl;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div style={{ height: '1em' }} />
        <ClientSelector fieldName="client" />
        <div style={{ height: '1em' }} />
        <div>
          <h6 className="shelf-form-title">
            {formatMessage({ defaultMessage: 'File' })}
          </h6>
          <div>
            <Field
              name="file"
              component={props => {
                const {
                  input,
                  meta: { invalid, error, touched },
                } = props;
                return (
                  <div>
                    <div
                      style={{ display: 'inline-block', marginRight: '1em' }}
                    >
                      <DropzoneInput
                        accept=".jpg, .jpeg, .png, .pdf"
                        component={
                          <FlatButton
                            className="flat-button-baby-blue"
                            label={formatMessage({
                              defaultMessage: 'Choose File',
                            })}
                          />
                        }
                        onChange={input.onChange}
                      />
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <TextField
                        id="upload-file-name"
                        disabled
                        value={!_.isEmpty(file) ? file[0].name : ''}
                      />
                    </div>
                    <div>
                      <span className="error-text">
                        {touched && invalid ? error : ''}
                      </span>
                    </div>
                    <div>
                      <span style={{ fontSize: '0.8em' }}>
                        <FormattedMessage defaultMessage=".pdf, .jpeg, .jpg, .png file types allowed." />
                      </span>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div style={{ height: '1em' }} />
        <FlatButton
          className="big-flat-button-green"
          style={{ marginTop: 40 }}
          label={formatMessage({ defaultMessage: 'SEND FILES' })}
          type="submit"
        />
      </form>
    );
  }
}

SendFiles.propTypes = propTypes;

SendFiles.defaultProps = {
  file: [],
};

const selector = formValueSelector('ProcessForm');
function mapStateToProps(state) {
  return {
    clients: state.client,
    file: selector(state, 'file'),
  };
}

function validate(values) {
  const errors = {};

  if (!values.client) {
    errors.client = (
      <FormattedMessage defaultMessage="Please choose a client." />
    );
  }

  if (!values.file || values.file.size === 0) {
    errors.file = <FormattedMessage defaultMessage="Please choose a file." />;
  }

  return errors;
}

export default injectIntl(
  connect(mapStateToProps, { createResource })(
    reduxForm({
      form: 'ProcessForm',
      validate,
    })(SendFiles),
  ),
);
